import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import PrimaryButton from '../PrimaryButton';
import "./GetInvolved.css";
import Img from "gatsby-image"
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const GetInvolved = ({ cta, excludeOrg, excludeAthlete, home }) => {

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "arrow-logo-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      blob: file(relativePath: { eq: "blob-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={`GetInvolved-outer-wrapper ${home ? "GetInvolved-outer-wrapper-home" : ""}`}>
      <div className={`GetInvolved-container ${excludeAthlete || excludeOrg ? "GetInvolved-one-button" : "" }`}>
      <div className="GetInvolved-arrow-wrapper">
        <div className="GetInvolved-arrow" style={{ transform: "rotate(-45deg)"}}>
          <Img fluid={data.logo.childImageSharp.fluid} />
        </div>
        <div className="GetInvolved-arrow">
          <Img fluid={data.logo.childImageSharp.fluid} />
        </div>
        <div className="GetInvolved-arrow" style={{ transform: "rotate(45deg)"}}>
          <Img fluid={data.logo.childImageSharp.fluid} />
        </div>
      </div>
      <div className="GetInvolved-text">{cta || "Join our community"}</div>
        {
          excludeAthlete ?
          null :
          <a href="/app/register?type=athlete">
            <PrimaryButton>
              {excludeOrg ? "Let's do this" : "Athlete"}
            </PrimaryButton>
          </a>
        }
        {
          excludeOrg ?
          null :
          <a href="/signup">
            <PrimaryButton>
              {excludeAthlete ? "Let's do this" : "Company"}
            </PrimaryButton>
          </a>
        }
      </div>
    </div>
  )
}

export default GetInvolved
