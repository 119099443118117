import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import PrimaryButton from '../PrimaryButton';
import "./WhatWeDo.css";
import Img from "gatsby-image"
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const WhatWeDo = () => {

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "arrow-logo-02.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div>
      <div className="WhatWeDo-contain Pivot-side-by-side" style={{ alignItems: "center", color: "#3240ff" }}>
        <div className="WhatWeDo-left-wrapper">
          <div className="Pivot-float-section WhatWeDo-left-section">
            <div className="Pivot-big-head WhatWeDo-head">
              Unite
            </div>
            <div className="Pivot-body-text" style={{ marginBottom: 20 }}>
              We unite elite women athletes
              and companies who have
              shared values to build their
              influence together.
            </div>
            <Link to="/how-it-works" className="Pivot-link Pivot-link-small">
              How we build connections
            </Link>
          </div>
        </div>
        <div className="WhatWeDo-plus-wrapper">
          <div style={{ position: "relative"}}>
            <div className="WhatWeDo-plus" style={{ left: -35 - 22, top: -35 }}>
              <Img fluid={data.logo.childImageSharp.fluid} />
            </div>
            <div className="WhatWeDo-plus">
              <Img fluid={data.logo.childImageSharp.fluid}  style={{ left: -22 }} />
            </div>
            <div className="WhatWeDo-plus" style={{ left: 35 - 22, top: 35 }}>
              <Img fluid={data.logo.childImageSharp.fluid} />
            </div>
          </div>
        </div>
        <div className="WhatWeDo-right-wrapper">
          <div className="Pivot-float-section WhatWeDo-right-section ">
            <div className="Pivot-big-head WhatWeDo-head">
              Equip
            </div>
            <div className="Pivot-body-text" style={{ marginBottom: 20 }}>
              We equip athletes with tools to
              be great beyond sports -
              during and after their
              playing days.
            </div>
            <Link to="/athletes/#benefits" className="Pivot-link Pivot-link-small">
              Athlete resources
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhatWeDo
