import ImageFrame from '../../images/blue-frame.svg';
import LeftFrame from '../../images/left-frame.svg';
import BottomFrame from '../../images/bottom-frame.svg';

import Image from '../image';
import React, { useEffect, useState, useRef } from "react";
import { useStaticQuery } from 'gatsby';
import "./Carousel.css";

const ATHLETE = [
  'Kendall Ellis',
  'Ani Rodriguez',
  'Paige Nielsen',
  'Jasmyne Spencer',
  'Christina McHale',
  'Grayson Murphy',
  'Bella Bixby',
  'Emily Boyd',
  'Mariah Lee',
  'Ali Riley',
  'Ani Rodriguez',
]

const COMPANY = [
  'Daily Crunch',
  'Wild Wonder',
  'Gorongosa Coffee',
  'Harper Coats',
  'Sweet Nothings',
  'MiiR',
  'Harper Coats',
  'WICK Sports',
  'Project Repat',
  'Rec Room',
  'Harper Coats',
]
const Carousel = () => {
  const [imgIdx, setImgIdx] = useState(0)
  const imgIdxTracker = useRef(0)
  useEffect(() => {
    const interval = setInterval(() => {
      imgIdxTracker.current = (imgIdxTracker.current + 1) % 11
      setImgIdx(imgIdxTracker.current)
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  const data = useStaticQuery(graphql`
    query {
      img3: file(relativePath: { eq: "athletes/a10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img10: file(relativePath: { eq: "athletes/a1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img0: file(relativePath: { eq: "athletes/a2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img9: file(relativePath: { eq: "athletes/a3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img1: file(relativePath: { eq: "athletes/a4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "athletes/a5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img5: file(relativePath: { eq: "athletes/a6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img4: file(relativePath: { eq: "athletes/a7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img7: file(relativePath: { eq: "athletes/a8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img6: file(relativePath: { eq: "athletes/a9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      img8: file(relativePath: { eq: "athletes/a11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `);

  return (
    <div style={{ width: '100%' }}>
      <div className="Carousel-wrapper-outer">
        <div className="Carousel-square"></div>
        <div className="Carousel-wrapper">
          <div className="Carousel-frame">
            <img src={ImageFrame} width="100%" style={{ margin: 0 }} />
          </div>
          <div style={{ opacity: 0, width: 0, height: 0, overflow: 'hidden' }}>
            {
              [0,1,2,3,4,5,6,7,8,9,10].map((v) => {
                return <Image src={data[`img${v}`].childImageSharp.fluid} width="400px" />
              })
            }
          </div>
          <div className="Carousel-img">
            <Image src={data[`img${imgIdx}`].childImageSharp.fluid} width="100%" />
          </div>
          <div className="Carousel-name">
            {`${ATHLETE[imgIdx]} X ${COMPANY[imgIdx]}`}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carousel