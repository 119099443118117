import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import PrimaryButton from '../PrimaryButton';
import "./Beliefs.css";
import Img from "gatsby-image"
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Beliefs = () => {

  const data = useStaticQuery(graphql`
    query {
      belief: file(relativePath: { eq: "webelieve.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div>
      <div className="Beliefs-contain">
        <div className="Beliefs-img">
          <Img fluid={data.belief.childImageSharp.fluid} />
        </div>
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <Link to="/about" className="Pivot-link">
          More about our vision
        </Link>
      </div>
    </div>
  )
}

export default Beliefs
