import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import GetInvolved from '../components/home/GetInvolved';
import WhatWeDo from '../components/home/WhatWeDo';
import Beliefs from '../components/home/Beliefs';
import AthleteStats from '../components/home/AthleteStats';
import Carousel from '../components/home/Carousel';

import "./index.css";
const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Doing cool things together" />
    <section className="home-first">
      <div className="home-first-inner">
        <div className="home-carousel">
          <Carousel />
        </div>
        <div div className="home-right">
          <div>
          <div className="home-hero">
            <div className="home-hero-small">
              <div className="home-hero-small-text">
                World-class women athletes
                <div className="home-hero-small-underline"></div>
              </div>
              <div className="home-hero-small-text">
                &nbsp;+ Innovative companies
                <div className="home-hero-small-underline"></div>
              </div>
            </div>
            <div className="home-hero-large">Doing cool<br/>things together</div>
          </div>
          <div>
            <GetInvolved home/>
          </div>
          </div>
        </div>
      </div>
    </section>
    <section className="Pivot-section-divider Pivot-section-divider-big-padding">
      <div className="Pivot-width-container">
        <div className="Pivot-small-head Pivot-section-head">
          <strong>What we do&nbsp;</strong>
          <span style={{ opacity: 0.2 }}>
            What we do What we do What we do What we do What we do What we do What we do
          </span>
        </div>
      </div>
      <div className="Pivot-width-container Pivot-section-spaced">
        <WhatWeDo />
      </div>
    </section>
    <section className="Pivot-section-divider">
      <div className="Pivot-width-container">
        <div className="Pivot-small-head Pivot-section-head" style={{ display: 'flex', flexDirection: "row-reverse", right: 0, textAlign: "right" }}>
          <strong>&nbsp; Why we do it</strong>
          <span style={{ opacity: 0.2 }}>
            Why we do it Why we do it Why we do it Why we do it Why we do it
            Why we do it Why we do it Why we do it Why we do it Why we do it
            Why we do it Why we do it Why we do it Why we do it Why we do it
          </span>
        </div>
      </div>
      <div className="Pivot-width-container Pivot-section-spaced">
        <AthleteStats />
        <Beliefs />
      </div>
    </section>
  </Layout>
)

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "top-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "image-frame.svg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage
