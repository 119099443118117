import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import PrimaryButton from '../PrimaryButton';
import "./AthleteStats.css";
import "../../pages/index.css";

import Img from "gatsby-image"
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const AthleteStats = () => {

  return (
    <div className="AthleteStats-stats">
      <div className="Pivot-width-container">
        <div className="AthleteStats-comm-area">
          <div className="Pivot-emphasis-text AthleteStats-head AthleteStats-head-desktop">
            <div className="home-hero-small-text">
              The influence of women athletes
              <div className="home-hero-small-underline"></div>
            </div>
            <div className="home-hero-small-text">
               is here to stay - and growing.
              <div className="home-hero-small-underline"></div>
            </div>
          </div>
          <div className="Pivot-emphasis-text AthleteStats-head AthleteStats-head-mobile">
            <div className="home-hero-small-text">
              The influence of
              <div className="home-hero-small-underline"></div>
            </div>
            <div className="home-hero-small-text">
              women athletes
              <div className="home-hero-small-underline"></div>
            </div>
            <div className="home-hero-small-text">
               is here to stay -
              <div className="home-hero-small-underline"></div>
            </div>
            <div className="home-hero-small-text">
               and growing.
              <div className="home-hero-small-underline"></div>
            </div>
          </div>
          <div className="about-grid-stats">
            <div className="AthleteStats-page-session">
              <div className="AthleteStats-page-session-header">
                <div className="AthleteStats-page-stats-head">A meaningful audience</div>
              </div>
              <div className="AthleteStats-page-session-body">
                14.3 million people in the United States watched the Women’s World Cup final in 2019 - more than the 11.4 million who watched the men’s final in 2018.
                <div className="AthleteStats-source-small">FOX Sports, 2019</div>
              </div>
            </div>
            <div className="AthleteStats-page-session">
              <div className="AthleteStats-page-session-header">
                <div className="AthleteStats-page-stats-head">Values that resonate</div>
              </div>
              <div className="AthleteStats-page-session-body">
                Women’s sports are regarded as more inspiring and progressive than men’s sports.
                <div className="AthleteStats-source-small">Nielsen, The Rise of Women’s Sports, 2018</div>
              </div>
            </div>
            <div className="AthleteStats-page-session">
              <div className="AthleteStats-page-session-header">
                <div className="AthleteStats-page-stats-head">Brand Awareness</div>
              </div>
              <div className="AthleteStats-page-session-body">
                75% of women’s sports fans can name at least one brand involved with women athletes.
                <div className="AthleteStats-source-small">Nielsen, The Rise of Women’s Sports, 2018</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AthleteStats
